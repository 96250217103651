<template>
  <div v-if="!group.hide" class="tourGroupItems no-scrollbar text-shadow" style="">
    <ul class="list-unstyle">
      <li v-for="(item,index) in groupItems" class="GroupItem" style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: top;position:relative;">
        <div class="picback" :class="{'active':senceGuid == item.guid}"></div>
        <div style="position:relative">
          <a @click="routerto(item)" style="color:#fff;cursor:pointer;">
            <div style="text-align:center;font-size:0;margin: 2px;">
              <div v-if="item" style="width:50px;height:50px;background-color:#ccc;display: inline-block;background-position:center;background-size:cover" :style="makeUrlStyle((((item.linkItem||{}).firstImage||{url:''}).url||'').replace('/0/0/0/0/', '/200/0/0/0/'))">
              </div>
            </div>
            <div class="text-truncate" style="text-align:center;font-size:8px;position:absolute;bottom: 0px;left: 2px;right: 2px;">
              {{item.title||'未命名'}}
            </div>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      hidebg() {
        return this.config.hidebg
      },
      tour() {
        return this.publicData.tour || {}
      },
      groupItems() {
        return this.publicData.groupItems || []
      },
      senceGuid() {
        return this.publicData.senceGuid
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
      group() {
        var groups = this.tour.groups || []
        for (var i in groups) {
          if (groups[i].guid == this.groupGuid) {
            return groups[i]
          }
        }
        return {}
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
        },
        events: {
        }
      })
    },
    destroyed() {
    },
    methods: {
      routerto(item, obj) {
        console.log('routerto')
        this.$emit('selectItem', item)
        this.getFunc({ target: 'Data', name: 'toPano' })(item.guid);
      //  this.$router.push({ query: { senceId: item.guid, from: this.$route.query.from,  ...obj } })
      },
      makeUrlStyle(url) {
        return {
          'background-image': "url('" + url + "')"
        }
      },
    },
  }
</script>
<style scoped>
  .tourGroupItems {
    white-space: nowrap;
    font-size: 14px;
    margin: 0;
    overflow: auto;
    opacity: 0.8;
    text-align: center;
    background-color: #0006;
  }

  .GroupItem {
    display: inline-block;
    margin: 5px;
    width: 4em;
    vertical-align: top;
  }

    .GroupItem.active {
      margin: 4px;
      border: 1px solid #fff;
      box-shadow: #000 0 0 2px 0;
    }

  .picback {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fffa;
    box-shadow: #0005 2px;
    box-shadow: #000 0 0 2px;
  }

    .picback.active {
      background-color: #ffbb00aa;
    }
</style>
